import { SET_IMAGE_URL, SET_IMAGE_DIMENSION } from "../constants";
// import '../../assets/images/resize.gif'

const imageDim = () => {
  if (window.innerWidth >= 600) return 600;
  else return 400;
};

const imageURL = () => {
  if (window.innerWidth >= 600) return "/assets/images/puzzle600.gif";
  else return "/assets/images/puzzle400.gif";
};

const dimension = { width: imageDim(), height: imageDim() };

const defaultState = {
  ...dimension,
  url: imageURL(),
};

const imageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_IMAGE_DIMENSION:
      const { width, height } = action.payload;
      return { ...state, width, height };
    case SET_IMAGE_URL:
      const url = action.payload;
      return { ...state, url };
    default:
      return state;
  }
};

export default imageReducer;
