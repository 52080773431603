import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Board } from "../components/board.component";
import { BoardContainer } from "../components/board-container.component";
import { useTilesPositionStatus } from "../hooks/tile-position-status.hook";
import { Button } from "../components/button.component";
import { UPDATE_GAME_STATUS } from "../store/constants";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin: 0;
`;

const StyledBoardContainer = styled(BoardContainer)`
  flex-direction: row;
  justify-content: space-evenly;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  padding: 1em;
  justify-content: center;
  border-radius: 7px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  flex: 1;
  position: absolute;
  z-index: 99;
`;

export const TimeLimitScreen = () => {
  const areTilesAligned = useTilesPositionStatus();
  const dispatch = useDispatch();
  const [isGameStarted, setIsGameStarted] = useState(false);

  useEffect(() => {
    if (areTilesAligned || !isGameStarted) {
      dispatch({ type: UPDATE_GAME_STATUS, payload: false });
    } else {
      dispatch({ type: UPDATE_GAME_STATUS, payload: true });
    }

    if (areTilesAligned && isGameStarted) {
      let message = document.querySelector(".BoardMessageContainer");
      let boardGame = document.querySelectorAll(".BoardGame");
      let gif = document.querySelector(".GifContainer");
      boardGame.forEach((game) => (game.style.opacity = 0));
      setTimeout(() => {
        message.style.display = "block";
      }, 300);
      setTimeout(() => {
        message.style.opacity = 1;
        gif.style.opacity = 1;
      }, 750);
    }
  }, [areTilesAligned, dispatch, isGameStarted]);

  const startGame = () => {
    setIsGameStarted(true);
  };

  return (
    <>
      <StyledBoardContainer>
        <Board />

        {areTilesAligned ? (
          <div className="BoardMessageContainer">
            <h1 className="BoardContainer__BoardMessage">
              That is correct!
              <br />
              <span className="BoardMessage__LetsSee">
                Now let's see what lies ahead.
              </span>
              <br />{" "}
              <span className="BoardMessage__StayTuned">Stay tuned...</span>
            </h1>
            <div className="BoardButton__newGame">
              <Link to="/">
                <Button>New Game</Button>
              </Link>
            </div>
          </div>
        ) : (
          !isGameStarted && (
            <ButtonsContainer>
              <StyledButton onClick={startGame}>Start</StyledButton>
            </ButtonsContainer>
          )
        )}
      </StyledBoardContainer>
    </>
  );
};
