import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { LayoutScreen } from "./layout.screen";
import { Button } from "../components/button.component";
import AZLogo from "../assets/images/AZLogo.png";

const Heading = styled.h1`
  text-align: center;
  font-size: 50px
  color: white;
  @media (max-width: 768px) {
    font-size: 40px
  }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding: 1em;
  padding-bottom: 0;
  justify-content: center;
  border-radius: 7px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  flex: 1;
`;

export const StyledButton = styled(Button)`
  min-width: 10em;
`;

export const BodyContent = () => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Heading className="MainMenu__HeadingTitle">
          The power of <span>ASK</span>?
        </Heading>

        <ButtonsContainer className="BodyContainer__buttonContainer">
          <Link to="/time-limit-mode">
            <StyledButton className="BodyContainer__button">
              Figure out
            </StyledButton>
          </Link>
        </ButtonsContainer>
      </div>

      <div className="footer ">
        <div className="footerContainer">
          <p>
            AstraZeneca Near East
            <br />
            Sweifieh, BLDG 19 Abdelraheem Al-Haj Mohammad street,
            <br />
            P.O. Box: 17788, Zip Code 11195 Amman-Jordan; Tel: +96265827580
            <br />
            For any adverse event report, product quality complaint or medical
            information request related to AZ products,
            <br />
            you can complete the web-based form accessible 24/7 via the URL:{" "}
            <span>http://contactazmedical.astrazeneca.com/</span>
            <br />
            For medical information requests, you can alternatively send to:{" "}
            <span>medinfo-ne@astrazeneca.com</span>
            <br />
            For adverse events reporting, you can alternatively send directly
            to: <span>Patientsafety.ne@astrazeneca.com</span>
            <br />
            Or Contact our office on Tel:{" "}
            <span>+96265827580 or +9611385534.</span>
          </p>
          <div className="logo-container">
            <img src={AZLogo} alt="AstraZenca logo" />
          </div>
        </div>
      </div>

      <div className="vertical-text">
        <p>SEL-387-iDC-0423-0424-IRQ</p>
      </div>
    </Container>
  );
};

export const MainMenuScreen = () => {
  return <LayoutScreen hideNavbar body={<BodyContent />} />;
};
